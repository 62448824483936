<template>
  <div class="wrapper">
    <h2 class="hline">Контакты</h2>
    <div class="address">
      <v-text-field
        label="Адрес"
        outlined
        dense
        v-model="getAddress.address.address"
        disabled
      ></v-text-field>
      <v-text-field
        label="Карта"
        outlined
        dense
        v-model="getAddress.address.map"
        disabled
      ></v-text-field>
      <v-btn small @click="form('address','адрес')">изменить</v-btn>
    </div>
    <div class="phone">
      <dir class="title-name">Телефоны</dir>
      <v-simple-table dark>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="width: 30px;">
                <v-btn icon color="green" @click="form('tel','телефон')" :title="`Добавить телефон`">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td>Добавить телефон</td>
            </tr>
            <tr v-for="(phone, p) in getAddress.tel" :key="'phone' + p">
              <td>
                <v-btn icon color="red" @click="__contactDelete('tel', phone.id)" :title="`Удалить телефон`">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </td>
              <td>{{phone.tel}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="fax">
      <dir class="title-name">Факс</dir>
      <v-simple-table dark>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="width: 30px;">
                <v-btn icon color="green" @click="form('fax','факс')" :title="`Добавить факс`">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td>Добавить факс</td>
            </tr>
            <tr v-for="(fax, f) in getAddress.fax" :key="'phone' + f">
              <td>
                <v-btn icon color="red" @click="__contactDelete('fax', fax.id)" :title="`Удалить факс`">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </td>
              <td>{{fax.fax}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="email">
      <dir class="title-name">Email</dir>
      <v-simple-table dark>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="width: 30px;">
                <v-btn icon color="green" @click="form('email','Email')" :title="`Добавить Email`">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </td>
              <td>Добавить Email</td>
            </tr>
            <tr v-for="(email, e) in getAddress.email" :key="'phone' + e">
              <td>
                <v-btn icon color="red" @click="__contactDelete('email', email.id)" :title="`Удалить Email`">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </td>
              <td>{{email.email}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-dialog
      v-model="items.access"
      persistent
      max-width="370"
    >
      <v-card>
        <div class="ins-form-content">
          <v-form ref="form">
            <v-text-field
              :label="`Введите ${items.label}`"
              outlined
              dense
              v-model="items.item"
              :rules="rules"
              v-if="items.access_contact"
            ></v-text-field>
            <v-text-field
              :label="`Введите адрес`"
              outlined
              dense
              v-model="items.address"
              :rules="rules"
              v-if="items.access_address"
            ></v-text-field>
            <v-text-field
              :label="`Введите iframe src карты`"
              outlined
              dense
              v-model="items.map"
              :rules="rules"
              v-if="items.access_address"
            ></v-text-field>
          </v-form>
          <div class="ins-phone-btn">
            <v-btn small color="red" @click="cancel()">отмена</v-btn>
            <v-btn small color="green" @click="__contactInsert()">сохранить</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  data: () => ({
    items: {
      access: false,
      access_address: false,
      access_contact: false,
      id: null,
      item: null,
      label: null,
      address: null,
      map: null
    },
    rules: [
      v => !!v || 'Поле не должно быть пустым!'
    ],
  }),
  computed: {
    ...mapGetters(['getAddress']),
  },
  methods: {
    ...mapActions(['address','contactInsert', 'contactDelete']),
    form: function(id, label) {
      if(id == 'tel' || id == 'fax' || id == 'email') {
        this.items.access_contact = true
        this.items.access = true
        this.items.id = id
        this.items.label = label
      }
      if(id == 'address') {
        this.items.access_address = true
        this.items.access = true
        this.items.id = id
        this.items.label = label
        this.items.address = this.getAddress.address.address
        this.items.map = this.getAddress.address.map
      }
    },
    __contactInsert: async function() {
      this.loadingWindow(true)
      let res = await this.contactInsert({
        id: this.items.id, 
        item: this.items.item,
        address: this.items.address,
        map: this.items.map
      })
      this.loadingWindow(false)
      if(res.contactInsert == true) {
        this.messageOn(`${this.items.label} сохранен`, 'green')
      } else {
        this.messageOn(`[ошибка:] ${this.items.label} не сохранен`, 'red')
      }
      this.cancel()
    },
    __contactDelete: async function(item, id) {
      this.loadingWindow(true)
      let res = await this.contactDelete({item, id})
      this.loadingWindow(false)
      if(res.contactDelete == true) {
        this.messageOn(`${this.items.label} удален`, 'green')
      } else {
        this.messageOn(`[ошибка:] ${this.items.label} не удален`, 'red')
      }
      this.cancel()
    },
    cancel: function() {
      this.items = {
        access: false,
        access_address: false,
        access_contact: false,
        id: null,
        item: null,
        label: null,
        address: null,
        map: null
      }
      if(this.$refs.form != undefined) this.$refs.form.reset()
      this.address()
    }
  }
}
</script>

<style lang="scss" scoped>
  .title-name {
    margin-bottom: 10px;
  }
  .address {
    button {
      margin: 0 3px;
    }
  }
  .phone, .fax, .email {
    margin-top: 25px;
  }
  .ins-form-content {
    padding: 15px;
    .ins-phone-btn {
      text-align: center;
      button {
        margin: 0 3px;
      }
    }
  }
</style>
